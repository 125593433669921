import React, { useState } from 'react';
import { StyleSheet, View, FlatList } from 'react-native';
import { Text, Switch, ToggleButton } from 'react-native-paper';
import i18n from 'i18n-js';

const inputPins = [
  'wandCenter', 'wandCounter', 'coldPumpCounter', 'coldThermometer', 'hotThermometer', 'outletThermometer', 'fanFeedback', 'loadCell', 'powerButton',
];

export default function PcbInput ({ style }) {
  const [inputs, setInputs] = useState(() => {
    return inputPins.map((value, index) => ({
      name: value,
      on: false,
      pass: false,
    }));
  });

  const renderInputCell = ({ item, index }) => {
    return (
      <View style={[styles.rowCell, { backgroundColor: item.pass === true ? '#7CFC00' :  item.pass === false ? 'tomato' : 'transparent' }]}>
        <Text style={styles.pinName}>
          {i18n.t('PinNames')[item.name]}
        </Text>
      </View>
    );
  };

  return (
    <FlatList
      style={[styles.container, style]}
      data={inputs}
      renderItem={renderInputCell}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  rowCell: {
    borderColor: 'black',
    borderWidth: 1,
    flexDirection: 'row',
    paddingHorizontal: 15,
    paddingVertical: 10,
    alignItems: 'center',
  },
  pinName: {
    fontSize: 25,
    marginVertical: 8,
  },
});
