import React, { useState, useEffect, useCallback, useContext } from 'react';
import { StyleSheet, View, FlatList, ScrollView } from 'react-native';
import { DataTable, Text } from 'react-native-paper';
import useSerialPort from '../hooks/useSerialPort';
import { LayoutContext } from '../components/Layout';
import FlexSvg from '../components/FlexSvg';
import { Path, G } from 'react-native-svg';
import { useFocusEffect } from '@react-navigation/native';
import i18n from 'i18n-js';
import * as d3 from 'd3';

const DutyDuration = 32 * 1000; // half a minute, with 2 second round trip time
const Duties = [12, 100, 80, 50, 25, 20, 15];
// const Duties = [12, 70, 60, 45, 40, 35, 30];

export default function HotPumpControl ({ style }) {
  const layoutContext = useContext(LayoutContext);
  const { write, subscribe, unsubscribe, connected } = useSerialPort();
  const [pumping, setPumping] = useState(false);
  const [activeDutyIndex, setActiveDutyIndex] = useState(-1);
  const [loadCellData, setLoadCellData] = useState(Duties.reduce((acc, duty) => {
    acc[duty.toString()] = [];
    return acc;
  }, {}));
  
  useEffect(() => {
    subscribe(handleStateReceivedEvent);
  }, []);

  useEffect(() => {
    if (activeDutyIndex >= Duties.length) {
      layoutContext.setFab(prevFab => ({ ...prevFab, visible: false }));
    } else {
      if (pumping) {
        layoutContext.setFab({
          visible: true,
          icon: 'stop',
          label: i18n.t('Stop'),
          onPress: () => write(JSON.stringify({ testCommand: 'stop' })),
        });
      } else {
        layoutContext.setFab({
          visible: true,
          icon: 'play',
          label: `${i18n.t('Start')} ${Duties[activeDutyIndex+1]}`,
          onPress: progressToNextDuty,
        });
      }
    }
  }, [pumping, activeDutyIndex]);

  useFocusEffect(useCallback(() => {
    const enquiryInterval = setInterval(sendEnquiry, 500);
    return () => {
      clearInterval(enquiryInterval);
      layoutContext.setFab(prevFab => ({ ...prevFab, visible: false, }));
    };
  }, []));

  const sendEnquiry = async () => {
    if (connected) {
      const string = Buffer.from([0x05]).toString('utf8');
      await write(string);
    }
  };

  const handleStateReceivedEvent = ({ detail: state }) => {
    const isPumping = state?.hotPump?.state === 1;
    setPumping(isPumping);
    if (isPumping) {
      // console.log(`handleStateReceivedEvent ${JSON.stringify({hotPump: state.hotPump, loadCell: state.loadCell}, null, 2)}`);
      const datum = {
        at: Date.now(),
        weight: state.loadCell.weight,
      };
      // const key = Duties[activeDutyIndex].toString();
      const key = (state.hotPump.speed * 100).toString();
      setLoadCellData(prevLoadCellData =>  ({
        ...prevLoadCellData,
        [key]: [
          ...prevLoadCellData[key],
          datum,
        ],
      }));
    }
  };

  useEffect(() => {
    console.log(JSON.stringify(loadCellData, null, 2));
  }, [loadCellData]);

  const progressToNextDuty = async () => {
    const commandPacket = {};
    if (activeDutyIndex + 1 === Duties.length) {
      commandPacket.testCommand = 'stop';
    } else {
      commandPacket.testCommand = 'hotPump';
      commandPacket.duty = Duties[activeDutyIndex + 1];
    }

    console.log(`progressToNextDuty ${JSON.stringify(commandPacket)}`);
    // await write(JSON.stringify({ testCommand: 'tare' }));
    await write(JSON.stringify(commandPacket));
    setTimeout(async () => {
      await write(JSON.stringify({ testCommand: 'stop' }));
      setActiveDutyIndex(prevActiveDutyIndex => prevActiveDutyIndex + 1);
      setPumping(false);
    }, DutyDuration);
  };

  const renderDuty = ({ item, index }) => {
    const data = loadCellData[item.toString()];
    const atFormat = new Intl.RelativeTimeFormat(navigator.language, { numeric: 'always', style: 'short' });
    return (
      <View style={styles.dutyContainer}>
        <Text>{item}</Text>
        <DataTable style={styles.dutyData}>
          <DataTable.Header>
            <DataTable.Title>{'time'}</DataTable.Title>
            <DataTable.Title>{'weight'}</DataTable.Title>
          </DataTable.Header>
          <ScrollView>
          {data.map((d, index) => (
            <DataTable.Row key={`weight-${activeDutyIndex}-${index}`}>
              <DataTable.Cell numeric>{atFormat.format((d.at - data[0].at)/1000, 'second')}</DataTable.Cell>
              <DataTable.Cell numeric>{(d.weight - data[0].weight).toFixed(2)}</DataTable.Cell>
            </DataTable.Row>
          ))}
          </ScrollView>
        </DataTable>
      </View>
    );
  };

  return (
    <View style={[styles.container, style]}>
      <FlatList
        style={styles.dutiesContainer}
        data={Object.keys(loadCellData)}
        renderItem={renderDuty}
        keyExtractor={item => item}
        ItemSeparatorComponent={({ highlighted, leadingItem }) => <View style={styles.dutiesSeparator} />}
        extraData={[loadCellData, activeDutyIndex]}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  dutiesContainer: {
    flex: 1,
  },
  dutyContainer: {
    flex: 1,
    height: 300,
    flexDirection: 'row',
  },
  dutyChart: {
    flex: 3,
  },
  dutyData: {
    flex: 2,
    height: '100%',
  },
  dutiesSeparator: {
    borderWidth: 1,
  },
});
