export default {
  Ok: 'OK',
  Connect: 'connect',
  Disconnect: 'disconnect',
  WebSerialNotSupported: 'web serial not supported',
  Connected: 'connected',
  NotConnected: 'not connected',

  State: 'state',
  Direction: 'direction',
  Target: 'target',
  Loop: 'loop',
  Speed: 'speed',
  Inwards: 'inwards',
  Outwards: 'outwards',
  MoveTo: 'move to',
  Stop: 'stop',
  Start: 'start',
  Set: 'set',
  Position: 'position',

  PinNames: {
    // output
    ['wandInwards']: 'move clockwise',
    ['wandOutwards']: 'move anti-clockwise',
    ['coldPump']: 'cold water pump',
    ['fan']: 'fan',
    ['coldPlate']: 'cold plate',
    ['loadCellClock']: 'load cell clock',
    ['heaterLow']: 'heater low',
    ['heaterHigh']: 'heater high',
    ['powerButtonBlue']: 'power button blue light',
    ['powerButtonWhite']: 'power button white light',
    ['hotPump']: 'hot water pump',
    ['led']: 'LED',
    // input
    ['wandCenter']: 'movement center switch',
    ['wandCounter']: 'movement counter',
    ['coldPumpCounter']: 'cold pump counter',
    ['coldThermometer']: 'cold plate thermometer',
    ['hotThermometer']: 'heater thermometer',
    ['outletThermometer']: 'outlet thermometer',
    ['fanFeedback']: 'fan feedback',
    ['loadCell']: 'load cell',
    ['powerButton']: 'power button',
  },
};
