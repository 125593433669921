import React, { useState } from 'react';
import { StyleSheet, View, FlatList } from 'react-native';
import { Text, Switch, ToggleButton } from 'react-native-paper';
import i18n from 'i18n-js';

const outputPins = [
  'wandInwards', 'wandOutwards', 'coldPump', 'fan', 'coldPlate', 'loadCellClock', 'heaterLow', 'heaterHigh', 'powerButtonBlue', 'powerButtonWhite', 'hotPump', 'led'
];

export default function PcbOutput ({ style }) {
  const [outputs, setOutputs] = useState(() => {
    return outputPins.map((value, index) => ({
      name: value,
      on: false,
      pass: null,
    }), []).sort();
  });

  const renderOutputCell = ({ item, index }) => {
    return (
      <View style={[styles.rowCell, { backgroundColor: item.pass === true ? '#7CFC00' :  item.pass === false ? 'tomato' : 'transparent' }]}>
        <View style={styles.pinNameAndControlContainer}>
          <Text style={styles.pinName}>
            {i18n.t('PinNames')[item.name]}
          </Text>
          <Switch
            value={item.on}
            onValueChange={() => setOutputs(prevOutputs => [
              ...prevOutputs.slice(0, index),
              {
                ...item,
                on: !item.on,
              },
              ...prevOutputs.slice(index + 1),
            ])}
          />
        </View>
        <ToggleButton.Row
          style={styles.passToggle}
          onValueChange={value =>
            setOutputs(prevOutputs => [
              ...prevOutputs.slice(0, index),
              {
                ...item,
                pass: value,
              },
              ...prevOutputs.slice(index + 1),
            ].sort())
          }
        >
          <ToggleButton
            icon="check"
            value={true}
          />
          <ToggleButton
            icon="close"
            value={false}
          />
        </ToggleButton.Row>
      </View>
    );
  };

  return (
    <FlatList
      style={[styles.container, style]}
      data={outputs}
      renderItem={renderOutputCell}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  rowCell: {
    borderColor: 'black',
    borderWidth: 1,
    flexDirection: 'row',
    paddingHorizontal: 15,
    paddingVertical: 10,
    alignItems: 'center',
  },
  pinNameAndControlContainer: {
    flex: 1,
  },
  passToggle: {},
  pinName: {
    fontSize: 25,
    marginVertical: 8,
  },
});
