import { useEffect } from 'react';
import { atom, selector, useRecoilState, useResetRecoilState, useRecoilValue } from 'recoil';
import DecodePacketTransformer from './DecodePacketTransformer';
import EncodePacketTransformer from './EncodePacketTransformer';

const serialPortAtom = atom({
  key: 'serialPort',
  default: null,
});

const serialPortWriter = selector({
  key: 'serialPortWriter',
  get: ({ get }) => {
    const port = get(serialPortAtom);
    if (port) {
      const wts = new TransformStream(new EncodePacketTransformer());
      const writableStreamClosed = wts.readable.pipeTo(port.writable);
      return wts.writable.getWriter();
    } else {
      return null;
    }
  },
});

const serialPortReader = selector({
  key: 'serialPortReader',
  get: ({ get }) => {
    const port = get(serialPortAtom);
    if (port) {
      const rts = new TransformStream(new DecodePacketTransformer());
      const rtsReadable = port.readable.pipeThrough(rts);
      return rtsReadable.getReader();
    } else {
      return null;
    }
  },
});

export default function useSerialPort() {
  const [serialPort, setSerialPort] = useRecoilState(serialPortAtom);
  const resetSerialPort = useResetRecoilState(serialPortAtom);
  const writer = useRecoilValue(serialPortWriter);
  const reader = useRecoilValue(serialPortReader);

  const eventTarget = new EventTarget();

  useEffect(() => {
    if (navigator.serial) {
      console.log('useSerialPort registering');
      navigator.serial.addEventListener('connect', event => console.log(`on serial connect, ${JSON.stringify(event)}`));
      navigator.serial.addEventListener('disconnect', event => console.log(`on serial disconnect, ${JSON.stringify(event)}`));
    }
  }, [navigator.serial]);

  useEffect(() => {
    if (reader) {
      reader.read().then(processData);
    }
  }, [reader]);

  const processData = ({ value, done }) => {
    if (done) {
      console.log("stream closed");
      // reader.releaseLock();
      return;
    }

    // value for fetch streams is a Uint8Array
    // console.log(`processData: length=${value.length} value=${value}`);
    try {
      const obj = JSON.parse(value);
      eventTarget.dispatchEvent(new CustomEvent('state', { detail: JSON.parse(value) }));
    } catch (err) {
      console.error('body of packet is not a JSON');
    }

    // Read some more, and call this function again
    return reader.read().then(processData);
  };

  const subscribe = callback => {
    eventTarget.addEventListener('state', callback);
  };
  const unsubscribe = callback => {
    eventTarget.removeEventListener('state', callback);
  };
  
  const connect = async () => {
    if (!'serial' in navigator) {
      throw new Error('WebSerialNotSupported');
    }

    try {
      const port = await navigator.serial.requestPort();
      await port.open({ baudRate: 115200 });

      // close the existing one only after making sure the new one can be opened
      if (connected) {
        serialPort.close();
      }
      setSerialPort(port);
    } catch (err) {
      if (err.name === 'NotFoundError') {} // user cancelled
      else { // unknown error
        console.error(err);
        throw new Error('NotConnected');
      }
    }
  };

  const disconnect = async () => {
    if (connected) {
      console.dir(reader);
      console.dir(writer);
      console.dir(serialPort);
      if (reader) {
        reader.cancel();
      }
      if (serialPort.writable.locked) {
        writer.releaseLock();
      }

      await serialPort.close();
      resetSerialPort();
    }
  }

  const connected = !!serialPort;

  const write = async (string) => {
    if (!connected) {
      throw new Error('NotConnected');
    }

    await writer.write(string);
  };

  return { serialPort, connected, connect, disconnect, write, subscribe, unsubscribe };
};
