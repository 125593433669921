import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Surface } from 'react-native-paper';

export default function StickyFooter(props) {
  return (
    <Surface style={[styles.container, props.style]}>
      {props.children}
    </Surface>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 16,
    backgroundColor: 'white',
    elevation: 5,
  },
});
