import React, { createContext, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Snackbar, ActivityIndicator, FAB, Button } from 'react-native-paper';
import i18n from 'i18n-js';

export const LayoutContext = createContext();

export default function Layout ({ navigationRef, children }) {
  const [fab, setFab] = useState({ visible: false, icon: '', label: '', onPress: null });
  const [snackbar, setSnackbar] = useState({ visible: false, text: null, action: null, bottomHeight: 10 });
  const [activityIndicator, setActivityIndicator] = useState({ visible: false });

  const contextValue = {
    setActivityIndicator,
    setSnackbar,
    setFab,
  };

  return (
    <LayoutContext.Provider value={contextValue}>
      <View style={styles.container}>
        {children}

        <View style={styles.fabContainer} pointerEvents="box-none">
          {fab.visible && !fab.hidden &&
          <Button
            mode="contained"
            style={styles.fab}
            icon={fab.icon}
            label={fab.label}
            onPress={fab.onPress}
          >
            {fab.label}
          </Button>
          }
        </View>

        <Snackbar
          style={{ marginBottom: snackbar.bottomHeight }}
          visible={snackbar.visible}
          onDismiss={() => setSnackbar(prevSnackbar => ({ ...prevSnackbar, visible: false }))}
          action={snackbar.action
            ? snackbar.action
            : {
                label: i18n.t('Ok'),
                onPress: () => setSnackbar(prevSnackbar => ({ ...prevSnackbar, visible: false })),
              }
          }
        >
          {snackbar.text}
        </Snackbar>

        {activityIndicator && activityIndicator.visible && (
          <ActivityIndicator
            style={styles.activityIndicator}
            size="large"
            hidesWhenStopped
          />
        )}
      </View>
    </LayoutContext.Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
  },
  activityIndicator: {
    ...StyleSheet.absoluteFill,
    backgroundColor: 'rgba(0.5, 0.5, 0.5, 0.5)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fabContainer: {
    position: 'absolute',
    bottom: 20,
    right: 40,
  },
  fab: {
  },
});
