import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import Svg from "react-native-svg";

const FlexSvg = (props) =>  {
  const [dimension, setDimension] = useState({ width: 0, height: 0 });

  const onLayout = (layoutChangeEvent) => {
    // console.log(`FlexSvg: width=${layoutChangeEvent.nativeEvent.layout.width}, height=${layoutChangeEvent.nativeEvent.layout.height}`);
    setDimension({
      height: layoutChangeEvent.nativeEvent.layout.height,
      width: layoutChangeEvent.nativeEvent.layout.width,
    });

    if (props.onLoad) {
      props.onLoad({
        width: layoutChangeEvent.nativeEvent.layout.width,
        height: layoutChangeEvent.nativeEvent.layout.height,
      });
    }
  }

  return (
    <View 
      style={props.style}
      onLayout={onLayout}
    >
      <Svg style={StyleSheet.absoluteFill} viewBox={props.viewBox ?? `0 0 ${dimension.width} ${dimension.height}`}>
        {props.children}
      </Svg>
    </View>
  );
}

export default FlexSvg;
