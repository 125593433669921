import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Text } from 'react-native-paper';
import i18n from 'i18n-js';
import StickyFooter from '../components/StickyFooter';
import ActionButton from '../components/ActionButton';

export default function LoadCell ({ style }) {

  return (
    <View
      style={[styles.container, style]}
    >
      <View style={styles.main}>
        <Text>{"已校正"}</Text>
      </View>
      <StickyFooter>
        <ActionButton>
          {"進行校正"}
        </ActionButton>
        <ActionButton>
          {"歸零"}
        </ActionButton>
      </StickyFooter>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  main: {
    flex: 1,
  },
});
