// ref: https://github.com/mdn/dom-examples/blob/master/streams/strings-transform-stream/Uint8ArrayToStringsTransformer.js
export default class DecodePacketTransformer {
  constructor() {
    this.cumulativeBuffer = Buffer.alloc(0);
  }

  /**
   * Receives the next Uint8Array chunk from `fetch` and transforms it.
   *
   * @param {Uint8Array} chunk The next binary data chunk.
   * @param {TransformStreamDefaultController} controller The controller to enqueue the transformed chunks to.
   */
  transform(chunk, controller) {
    // console.log(`Received ${chunk.byteLength} bytes: ${Buffer.from(chunk, 'hex').toString('utf8')}`);
    this.cumulativeBuffer = Buffer.concat([this.cumulativeBuffer, Buffer.from(chunk)], this.cumulativeBuffer.byteLength + chunk.byteLength);
    // console.log(this.cumulativeBuffer.toString('utf8'));

    const lineFeedIndex = this.cumulativeBuffer.indexOf(0x0A);
    if (lineFeedIndex >= 0) {
      let str = '';
      for (let i = 0; i < lineFeedIndex; i++) {
        str = `${str} ${this.cumulativeBuffer[i].toString('16')}`;
      }
      // console.log(`line ${lineFeedIndex}: ${Buffer.from(this.cumulativeBuffer.subarray(0, lineFeedIndex+1), 'hex').toString('utf8')}`);
      this.cumulativeBuffer = this.cumulativeBuffer.slice(lineFeedIndex + 1);
    }

    const sohIndex = this.cumulativeBuffer.indexOf(0x01);
    const eotIndex = this.cumulativeBuffer.indexOf(0x04);

    // TODO: handle more than one packet in the chunk
    if (sohIndex >= 0) {
      if (eotIndex > sohIndex) {
        const packet = this.cumulativeBuffer.subarray(sohIndex, eotIndex + 1);

        let str = '';
        for (let i = 0; i < packet.length; i++) {
          str = `${str} ${packet[i].toString(16)}`;
        }
        // console.log(`packet = ${str}`);
        // console.log(`DecodePacketTransformer::transform: packet = ${(new Uint8Array(packet)).map(byte => byte.toString(16)).join(' ')}`);

        // data block starts at 2, and ends before last 2
        const data = packet.slice(2, packet.byteLength - 2);
        this.enqueueData(controller, data);

        if (eotIndex === this.cumulativeBuffer.length) {
          this.clearBuffer();
        } else {
          this.cumulativeBuffer = this.cumulativeBuffer.slice(eotIndex + 1);
        }
      }
    } else {
      this.clearBuffer();
    }
  }

  enqueueData(controller, data) {
    const string = Buffer.from(data, 'hex').toString('utf8');
    controller.enqueue(string);
  }

  clearBuffer() {
    this.cumulativeBuffer = Buffer.alloc(0);
  }

  /**
   * Is called when `fetch` has finished writing to this transform stream.
   *
   * @param {TransformStreamDefaultController} controller The controller to enqueue the transformed chunks to.
   */
  flush(controller) {
    // Is there still a line left? Enqueue it
    if (this.lastString) {
      controller.enqueue(this.lastString);
    }
  }
}
