import React from 'react';
import { StyleSheet } from 'react-native';
import { Button } from 'react-native-paper';

export default function ActionButton ({ style, onPress, children }) {
  return (
    <Button
      style={[styles.container, style]}
      contentStyle={styles.content}
      mode="contained"
      onPress={onPress}
    >
      {children}
    </Button>
  );
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 10,
    // paddingHorizontal: 10,
  },
  content: {
    // marginHorizontal: 10,
  },
})