import React from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import CustomDrawerContent from './components/CustomDrawerContent';
import Home from './screens/Home.js';
import HotDripTest from './screens/HotDripTest';
import DripHeadControl from './screens/DripHeadControl';
import HotPumpControl from './screens/HotPumpControl';
import HotPumpCalibration from './screens/HotPumpCalibration';
import PcbInput from './screens/PcbInput';
import PcbOutput from './screens/PcbOutput';
import LoadCell from './screens/LoadCell';
import HotBuffer from './screens/HotBuffer';
import Empty from './screens/Empty';

const Drawer = createDrawerNavigator();

export default function Router () {
  return (
    <Drawer.Navigator
      drawerType="permanent"
      initialRouteName="Home"
      drawerContent={props => <CustomDrawerContent {...props} />}
    >
      <Drawer.Screen name="Home" component={Home} />

      <Drawer.Screen
        name="Development/HotDripTest"
        component={HotDripTest}
        options={{ title: 'Hot Drip Test' }}
      />
      <Drawer.Screen
        name="Development/DripHeadControl"
        component={DripHeadControl}
        options={{ title: 'Drip Head Control' }}
      />
      <Drawer.Screen
        name="Development/HotPumpControl"
        component={HotPumpControl}
        options={{ title: 'Hot Pump Control' }}
      />
      <Drawer.Screen
        name="Development/HotPumpCalibration"
        component={HotPumpCalibration}
        options={{ title: 'Hot Pump Calibration' }}
      />
      <Drawer.Screen
        name="Development/HotBuffer"
        component={HotBuffer}
        options={{ title: 'Hot Buffer' }}
      />

      <Drawer.Screen
        name="PCB/Input"
        component={PcbInput}
        options={{ title: 'PCB Input' }}
      />
      <Drawer.Screen
        name="PCB/Output"
        component={PcbOutput}
        options={{ title: 'PCB Output' }}
      />

      <Drawer.Screen
        name="Whole Machine/Water Level"
        component={LoadCell}
        options={{ title: 'Water Level' }}
      />
      <Drawer.Screen
        name="Whole Machine/Load Cell"
        component={LoadCell}
        options={{ title: 'Load Cell' }}
      />
      <Drawer.Screen
        name="Whole Machine/Outlet Thermometer"
        component={LoadCell}
        options={{ title: 'Outlet Thermometer' }}
      />

      <Drawer.Screen
        name="Cooling/thermometer"
        component={Empty}
        options={{ title: 'Thermometer Test' }}
      />
      <Drawer.Screen
        name="Cooling/cooling"
        component={Empty}
        options={{ title: 'Cooling Test' }}
      />

      <Drawer.Screen
        name="Cold Pump/Encoder"
        component={Empty}
        options={{ title: 'Encoder Test' }}
      />
      
      <Drawer.Screen
        name="Heater/Thermometer"
        component={Empty}
        options={{ title: 'Thermometer Test' }}
      />
      <Drawer.Screen
        name="Heater/Heating"
        component={Empty}
        options={{ title: 'Heating Test' }}
      />

      <Drawer.Screen
        name="Nozzle/Motion"
        component={Empty}
        options={{ title: 'Motion Test'}}
      />
      <Drawer.Screen
        name="Nozzle/Encoder"
        component={Empty}
        options={{ title: 'Encoder Test' }}
      />
      <Drawer.Screen
        name="Nozzle/LED"
        component={Empty}
        options={{ title: 'LED Test' }}
      />

    </Drawer.Navigator>
  );
}

export const linkingConfig = {
  screens: {
    Home: '',
    HotDripTest: 'hot-drip',
    DripHeadControl: 'drip-head',
    HotPumpControl: 'hot-pump',
  },
};
