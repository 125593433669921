export default {
  Ok: '確認',
  Connect: '連接',
  Disconnect: '取消連接',
  WebSerialNotSupported: '此瀏覽器不支援web serial',
  Connected: '已連接',
  NotConnected: '連接失敗',

  Invalid: '無效',
  InvalidInput: '輸入格式不正確',

  State: '狀態',
  Direction: '方向',
  Target: '目標',
  Loop: '迴轉',
  Speed: '速度',
  Inwards: '往內',
  Outwards: '往外',
  MoveTo: '移往',
  Stop: '停止',
  Start: '開始',
  Set: '設定',
  Position: '位置',
  MaxFlowRate: '最大輸出率',
  FloatRateUnit: '毫升/小時',

  PinNames: {
    // output
    ['wandInwards']: 'move clockwise',
    ['wandOutwards']: 'move anti-clockwise',
    ['coldPump']: 'cold water pump',
    ['fan']: 'fan',
    ['coldPlate']: 'cold plate',
    ['loadCellClock']: 'load cell clock',
    ['heaterLow']: 'heater low',
    ['heaterHigh']: 'heater high',
    ['powerButtonBlue']: 'power button blue light',
    ['powerButtonWhite']: 'power button white light',
    ['hotPump']: 'hot water pump',
    ['led']: 'LED',
    // input
    ['wandCenter']: 'movement center switch',
    ['wandCounter']: 'movement counter',
    ['coldPumpCounter']: 'cold pump counter',
    ['coldThermometer']: 'cold plate thermometer',
    ['hotThermometer']: 'heater thermometer',
    ['outletThermometer']: 'outlet thermometer',
    ['fanFeedback']: 'fan feedback',
    ['loadCell']: 'load cell',
    ['powerButton']: 'power button',
  },
};
