import React, { useState, useEffect, useCallback, useContext } from 'react';
import { StyleSheet, View } from 'react-native';
import { TextInput, Text, Title, HelperText } from 'react-native-paper';
import i18n from 'i18n-js';
import { useFocusEffect } from '@react-navigation/native';
import useSerialPort from '../hooks/useSerialPort';
import StickyFooter from '../components/StickyFooter';
import ActionButton from '../components/ActionButton';
import { LayoutContext } from '../components/Layout';

export default function HotPumpCalibration ({ style }) {
  const layoutContext = useContext(LayoutContext);
  const { write, subscribe, connected } = useSerialPort();
  const [hotPumpState, setHotPumpState] = useState({})
  const [newMaxFlowRate, setNewMaxFlowRate] = useState();
  const [newA, setNewA] = useState();
  const [newB, setNewB] = useState();

  useEffect(() => {
    subscribe(handleStateReceivedEvent);
  }, []);

  const handleStateReceivedEvent = ({ detail: state }) => {
    if (
      state.hotPump.maxFlowRate !== hotPumpState.maxFlowRate ||
      state.hotPump.a !== hotPumpState.a || 
      state.hotPump.b !== hotPumpState.b 
    ) {
      console.log(`hot pump figure updated`);
    }
    setHotPumpState({
      state: state.hotPump.state,
      speed: state.hotPump.speed,
      maxFlowRate: state.hotPump.maxFlowRate,
      a: state.hotPump.a,
      b: state.hotPump.b,
    });
  };

  useFocusEffect(useCallback(() => {
    const enquiryInterval = setInterval(sendEnquiry, 2000);
    return () => {
      clearInterval(enquiryInterval);
    };
  }, []));

  const sendEnquiry = async () => {
    if (connected) {
      const string = Buffer.from([0x05]).toString('utf8');
      await write(string);
    }
  };

  const onSetCalibration = async () => {
    console.log(`newMaxFlowRate=${newMaxFlowRate},newA=${newA},newB=${newB}`);
    if (isNaN(newMaxFlowRate) || isNaN(newA) || isNaN(newB)) {
      layoutContext.setSnackbar({ visible: true, text: i18n.t('InvalidInput') });
      return;
    }
    const commandPacket = {
      testCommand: 'hotPump',
      maxFlowRate: newMaxFlowRate,
      a: newA,
      b: newB,
    };
    await write(JSON.stringify(commandPacket));
  };

  const renderVariableRow = (label, value, unit, onChangeText, isValid) => {
    return (
      <>
        <View style={styles.variableRowContainer}>
          <Title style={styles.label}>
            {`${label}: `}
          </Title>
          <Text style={styles.value}>
            {`${value} ${unit}`}
          </Text>
          <TextInput
            mode="outlined"
            style={styles.setValueTextInput}
            defaultValue={value}
            onChangeText={onChangeText}
          />
        </View>
        <HelperText
          style={styles.setValueTextInputErrorText}
          type="error"
          visible={!isValid()}
        >
          {i18n.t('Invalid')}
        </HelperText>
      </>
    );
  };

  return (
    <View
      style={[styles.container, style]}
    >
      <View style={styles.main}>
        {renderVariableRow(i18n.t('MaxFlowRate'), hotPumpState?.maxFlowRate, i18n.t('FloatRateUnit'), setNewMaxFlowRate, () => !isNaN(newMaxFlowRate))}
        {renderVariableRow('a', hotPumpState?.a, '', setNewA, () => !isNaN(newA))}
        {renderVariableRow('b', hotPumpState?.b, '', setNewB, () => !isNaN(newB))}
      </View>
      <StickyFooter>
        <ActionButton onPress={onSetCalibration}>
          {i18n.t("Set")}
        </ActionButton>
      </StickyFooter>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  main: {
    flex: 1,
    padding: 16,
    justifyContent: 'center',
  },
  variableRowContainer: {
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  label: {
    flex: 1,
    textAlign: 'right',
    paddingRight: 20,
  },
  value: {
    flex: 1,
  },
  setValueTextInput: {
    flex: 2,
  },
  setValueTextInputErrorText: {
    alignSelf: 'flex-end',
  },
  setButton: {
    flex: 1,
  },
});
