import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import i18n from 'i18n-js';

export default function PcbInput ({ style }) {

  return (
    <View
      style={[styles.container, style]}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
