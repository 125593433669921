import React, { useContext, useState, useEffect, useCallback } from 'react';
import { StyleSheet, View, TextInput } from 'react-native';
import { LayoutContext } from '../components/Layout';
import useSerialPort from '../hooks/useSerialPort';
import FlexSvg from '../components/FlexSvg';
import { Path, G, Circle } from 'react-native-svg';
import { useFocusEffect } from '@react-navigation/native';
import { useTheme, Text, Button, HelperText } from 'react-native-paper';
import i18n from 'i18n-js';
import Slider from '@react-native-community/slider';

const MaxPosition = 84 * 9;

export default function DripHeadControl ({ style }) {
  const layoutContext = useContext(LayoutContext);
  const { write, subscribe, connected } = useSerialPort();
  const theme = useTheme();
  const [spiralDiagramDimension, setSpiralDiagramDimension] = useState({ width: 0, height: 0 });
  const [spiralPoints, setSpiralPoints] = useState([]);
  const [targetPositionEntry, setTargetPositionEntry] = useState('0');
  const [targetSpeedEntry, setTargetSpeedEntry] = useState(1);
  const [dripHeadState, setDripHeadState] = useState(null);

  useEffect(() => {
    if (spiralDiagramDimension.width > 0 && spiralDiagramDimension.height > 0) {
      const origin = { x: spiralDiagramDimension.width / 2, y: spiralDiagramDimension.height / 2 };
      const padding = Math.min(spiralDiagramDimension.width, spiralDiagramDimension.height) / 2 / 9;
      const points = getSpiralPoints(origin, 9, 84 * 9, true, padding);
      setSpiralPoints(points);
    }
  }, [spiralDiagramDimension]);

  useEffect(() => {
    subscribe(handleStateReceivedEvent);
  }, []);

  useFocusEffect(useCallback(() => {
    const enquiryInterval = setInterval(sendEnquiry, 500);
    return () => {
      clearInterval(enquiryInterval);
    };
  }, []));

  const sendEnquiry = async () => {
    if (connected) {
      const string = Buffer.from([0x05]).toString('utf8');
      await write(string);
    }
  };

  const handleStateReceivedEvent = ({ detail: state }) => {
    // console.log(`handleStateReceivedEvent ${JSON.stringify(state)}`);
    const wandState = state.wand;
    setDripHeadState({
      state: wandState.state,
      position: wandState.position,
      speed: wandState.speed,
      direction: wandState.direction,
      targetPosition: wandState.targetPosition,
    });
  };

  const getSpiralPoints = (origin, revolutions, pointCount, clockwise, padding) => {
    const roundTo = (input, sigdigs) => {
      return Math.round(input * Math.pow(10, sigdigs) ) / Math.pow(10, sigdigs);
    };

    let direction = clockwise ? 1 : -1;
    let circ = padding / (2 * Math.PI);
    let step = (2 * Math.PI * revolutions) / pointCount;
    let points = [], angle, x, y;
    for (let i = 0; i <= pointCount ; i++){
      angle = direction * step * i;
      x = roundTo((circ * angle) * Math.cos(angle) + origin.x, 2);
      y = roundTo((circ * angle) * Math.sin(angle) + origin.y, 2);
      points.push({ x, y });
    }

    return points;
  };

  const getSpiralPath = () => {
    let path = `M ${spiralDiagramDimension.width / 2},${spiralDiagramDimension.height / 2}`;
    if (spiralPoints.length > 0) {
      path += ` S ${spiralPoints.slice(1).map(p => `${p.x},${p.y}`).join(' ')}`
    }
    return path;
  };

  const handlePositionPress = async (position) => {
    const commandPacket = {
      testCommand: 'wand',
      targetPosition: position,
    };
    await write(JSON.stringify(commandPacket));
  };

  const handleDirectionPress = async (direction) => {
    const commandPacket = {
      testCommand: 'wand',
      direction,
    };
    await write(JSON.stringify(commandPacket));
  };

  const handleSpeedPress = async (speed) => {
    const commandPacket = {
      testCommand: 'wand',
      speed,
    };
    await write(JSON.stringify(commandPacket));
  };
  const handleStopPress = async () => {
    await handleSpeedPress(0);
  };

  const targetPositionEntryIsValid = () => {
    const targetPosition = parseInt(targetPositionEntry);
    return isNaN(targetPosition) === false && targetPosition >= 0 && targetPosition < MaxPosition;
  };

  return (
    <View style={[styles.container, style]}>
      <View style={styles.displayContainer}>
        <View style={[styles.compartmentContainer, { borderColor: theme.colors.accent }]}>
          <Text style={styles.stateDisplayText}>{`${i18n.t('State')} = ${dripHeadState?.state}`}</Text>
        </View>
        <View style={[styles.compartmentContainer, { borderColor: theme.colors.accent }]}>
          <Text style={styles.stateDisplayText}>{`${i18n.t('Direction')} = ${dripHeadState?.direction}`}</Text>
        </View>
        <View style={[styles.compartmentContainer, { borderColor: theme.colors.accent }]}>
          <Text style={styles.stateDisplayText}>{`${i18n.t('Target')} = ${dripHeadState?.targetPosition}`}</Text>
        </View>
        <View style={[styles.compartmentContainer, { borderColor: theme.colors.accent }]}>
          <Text style={styles.stateDisplayText}>{`${i18n.t('Speed')} = ${dripHeadState?.speed}`}</Text>
        </View>
        <View style={[styles.compartmentContainer, { borderColor: theme.colors.accent }]}>
          <Text style={styles.stateDisplayText}>{`${i18n.t('Position')} = ${dripHeadState?.position}`}</Text>
        </View>
      </View>
      <FlexSvg style={[styles.compartmentContainer, styles.spiralDiagram, { borderColor: theme.colors.accent }]} onLoad={setSpiralDiagramDimension}>
        <Path d={getSpiralPath()} fill="none" stroke="gray" strokeWidth={1} />
        <G>
          {spiralPoints.map((p, index) => (
            <Circle
              key={`circle-${index}`}
              cx={p.x} cy={p.y}
              stroke="red"
              strokeWidth={dripHeadState?.targetPosition === index ? 1 : 0}
              r={dripHeadState?.position === index || dripHeadState?.targetPosition === index ? 5 : 3}
              fill={dripHeadState?.position === index ? 'red' : dripHeadState?.targetPosition === index ? 'transparent' : theme.colors.accent}
              onPress={() => handlePositionPress(index)}
            />
          ))}
        </G>
      </FlexSvg>
      <View style={styles.controlsContainer}>
        <View style={[styles.compartmentContainer, { borderColor: theme.colors.accent }]}>
          <View style={{ flex: 1, flexDirection: 'row' }}>
            <Button
              style={styles.controlButton}
              mode="contained"
              onPress={() => handleDirectionPress(1)}
            >
              {i18n.t('Inwards')}
            </Button>
            <Button
              style={styles.controlButton}
              mode="contained"
              onPress={() => handleDirectionPress(2)}
            >
              {i18n.t('Outwards')}
            </Button>
          </View>
          <View style={{ flex: 1, flexDirection: 'row' }}>
            <View style={{ margin: 5 }}>
              <TextInput
                style={{ flex: 1, borderWidth: 1 }}
                placeholder={`0 .. ${MaxPosition}`}
                onChangeText={setTargetPositionEntry}
              />
              <HelperText type="error" visible={!targetPositionEntryIsValid()}>{`0 .. ${MaxPosition}`}</HelperText>
            </View>
            <Button
              style={styles.controlButton}
              mode="contained"
              onPress={() => {
                if (targetPositionEntryIsValid()) {
                  handlePositionPress(parseInt(targetPositionEntry));
                }
              }}
            >
              {i18n.t('MoveTo')}
            </Button>
          </View>
        </View>
        <View style={[styles.compartmentContainer, { borderColor: theme.colors.accent }]}>
          <View style={{ marginHorizontal: 20 }}>
            <Slider
              minimumValue={0}
              maximumValue={1}
              value={1}
              onValueChange={setTargetSpeedEntry}
            />
          </View>
          <View style={{ flex: 1, flexDirection: 'row' }}>
            <Button
              style={styles.controlButton}
              mode="contained"
              onPress={handleStopPress}
            >
              {i18n.t('Stop')}
            </Button>
            <Text>{`${Math.round(targetSpeedEntry * 100)}%`}</Text>
            <Button
              style={styles.controlButton}
              mode="contained"
              onPress={() => handleSpeedPress(targetSpeedEntry)}
            >
              {i18n.t('Set')}
            </Button>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  spiralDiagram: {
    flex: 4,
  },
  compartmentContainer: {
    flex: 1,
    borderWidth: 1,
    justifyContent: 'center',
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  displayContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  controlsContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  controlButton: {
    margin: 5,
    justifyContent: 'center',
  },
  stateDisplayText: {
    fontSize: 22,
  },
});
