export default class EncodePacketTransformer {
  constructor() {
    this.msgId = 0;
  }

/**
  * @param {Uint8Array} chunk The next binary data chunk.
  * @param {TransformStreamDefaultController} controller The controller to enqueue the transformed chunks to.
  */
  transform(chunk, controller) {
    const dataBuffer = Buffer.from(chunk, 'utf8');

    // SOH, msgId, msgLength, STX, data, ETX, EOT
    // const packet = Buffer.alloc(1 + 4 + 4 + 1 + dataBuffer.length + 1 + 1);
    const packet = Buffer.alloc(1 + 1 + dataBuffer.length + 1 + 1);
    packet.writeUInt8(0x01, 0);
    // TODO: format header block
    // packet.writeInt32LE(this.msgId++, 1);
    // packet.writeInt32LE(dataBuffer.length, 5);
    packet.writeUInt8(0x02, 1);
    
    dataBuffer.copy(packet, 2, 0, dataBuffer.length);

    packet.writeUInt8(0x03, packet.length - 2);
    packet.writeUInt8(0x04, packet.length - 1);

    let str = '';
    for (let i = 0; i < packet.length; i++) {
      str = `${str} ${packet[i].toString(16)}`;
    }
    // console.log(`packet = ${str}`);
    // console.log(`EncodePacketTransformer::transform: packet = ${(new Uint8Array(packet)).map(byte => byte.toString(16)).join(' ')}`);
    controller.enqueue(packet);
  }

  flush(controller) {}
}
