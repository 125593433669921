import React, { useRef } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { Provider as PaperProvider } from 'react-native-paper';
import { RecoilRoot } from 'recoil';
import Layout from './src/components/Layout';
import Router, { linkingConfig } from './src/router';
import theme from './src/assets/theme';
import i18n from './src/assets/i18n';

export default function App() {
  const navigationRef = useRef();

  return (
    <RecoilRoot>
      <PaperProvider theme={theme}>
        <NavigationContainer ref={navigationRef} linking={linking}>
          <Layout navigationRef={navigationRef}>
            <Router />
          </Layout>
        </NavigationContainer>
      </PaperProvider>
    </RecoilRoot>
  );
}

const linking = {
  prefixes: 'http://localhost:19006',
  config: linkingConfig,
};
