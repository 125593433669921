import React, { useEffect, useContext } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button } from 'react-native-paper';
import i18n from 'i18n-js';
import { LayoutContext } from '../components/Layout';
import useSerialPort from '../hooks/useSerialPort';

export default function Home ({ navigation, style }) {
  const layoutContext = useContext(LayoutContext);
  const { connected, connect, disconnect } = useSerialPort();

  const handleConnectionTogglePress = async () => {
    try {
      if (connected) {
        await disconnect();
      } else {
        await connect();
      }
    }
    catch (err) {
      console.error(err);
      layoutContext.setSnackbar(prevSnackbar => ({ ...prevSnackbar, visible: true, text: i18n.t(err.message) }));
    }
  };

  useEffect(() => {
    if (connected) {
      layoutContext.setSnackbar(prevSnackbar => ({ ...prevSnackbar, visible: true, text: i18n.t('Connected') }));
    }
  }, [connected]);

  return (
    <View style={[styles.container, style]}>
      <Button onPress={handleConnectionTogglePress} mode="contained">
        {connected ? i18n.t('Disconnect') : i18n.t('Connect')}
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
