import React, { useState, useEffect, useCallback } from 'react';
import { StyleSheet, View } from 'react-native';
import { ToggleButton, Text, Headline, IconButton, Card, useTheme } from 'react-native-paper';
import { useFocusEffect } from '@react-navigation/native';
import Slider from '@react-native-community/slider';
import i18n from 'i18n-js';
import useSerialPort from '../hooks/useSerialPort';

const toggleButtonSize = 60;

export default function HotBuffer ({ style }) {
  const theme = useTheme();
  const { write, subscribe, connected } = useSerialPort();
  const [hotWaterValveState, setHotWaterValveState] = useState({});
  const [hotPumpState, setHotPumpState] = useState({});
  const [boilerTemperature, setBoilerTemperature] = useState(0);
  const [outletTemperature, setOutletTemperature] = useState(0);
  const [boilererWaterLevel, setBoilerWaterLevel] = useState();
  const [heaterState, setHeaterState] = useState({});

  const [targetValveState, setTargetValveState] = useState(0);
  const [targetPumpSpeed, setTargetPumpSpeed] = useState(0);
  const [targetBoiler, setTargetBoiler] = useState({ high: 0, low: 0 });

  const [boilerThermometerToggled, setBoilerThermometerToggled] = useState(false);
  const [outletThermometerToggled, setOutletThermometerToggled] = useState(false);

  useEffect(() => {
    const commandPacket = {
      testCommand: 'setHotWaterValve',
      targetState: targetValveState,
    };
    write(JSON.stringify(commandPacket));
  }, [targetValveState]);

  useEffect(() => {
    subscribe(handleStateReceivedEvent);
  }, []);

  const handleStateReceivedEvent = async ({ detail: state }) => {
    // console.log(`handleStateReceivedEvent: ${JSON.stringify(state.hotWaterValve, null, 2)}`)
    // console.log(`handleStateReceivedEvent: hotThermometer ${JSON.stringify(state.hotThermometer, null, 2)}`)
    // console.log(`handleStateReceivedEvent: outletThermometer ${JSON.stringify(state.outletThermometer, null, 2)}`)
    // console.log(`handleStateReceivedEvent: waterLevel ${JSON.stringify(state.boilerWaterSensor, null, 2)}`)
    // console.log(`handleStateReceivedEvent: ${JSON.stringify(state, null, 2)}`)
    console.log(`handleStateReceivedEvent: heater ${JSON.stringify(state.heater, null, 2)}`)
    
    setHotWaterValveState({
      state: state.hotWaterValve.state,
      targetState: state.hotWaterValve.targetState,
      moving: state.hotWaterValve.moving,
    });

    // console.log(`hotPumpState = ${JSON.stringify(state.hotPump, null, 2)}`);

    setHotPumpState({
      state: state.hotPump.state,
      speed: state.hotPump.speed,
      maxFlowRate: state.hotPump.maxFlowRate,
      a: state.hotPump.a,
      b: state.hotPump.b,
    });

    setHeaterState({
      high: state.heater.high,
      low: state.heater.low,
    });

    if (state.boilerWaterSensor.state === 0) {
      const commandPacket = {
        testCommand: 'startBoilerWaterSensor',
      };
      await write(JSON.stringify(commandPacket));
    } else {
      setBoilerWaterLevel(state.boilerWaterSensor.level);
    }

    if (state.hotThermometer.state === 0) {
      if (!boilerThermometerToggled) {
        setBoilerThermometerToggled(true);
        const commandPacket = {
          testCommand: 'toggleThermometer',
          thermometer: 1,
        };
        await write(JSON.stringify(commandPacket));
      }
    } else {
      setBoilerTemperature(state.hotThermometer.temperature);
    }
    if (state.outletThermometer.state === 0) {
      if (!outletThermometerToggled) {
        setOutletThermometerToggled(true);
        const commandPacket = {
          testCommand: 'toggleThermometer',
          thermometer: 0,
        };
        await write(JSON.stringify(commandPacket));
      }
    } else {
      setOutletTemperature(state.outletThermometer.temperature);
    }
  };

  useFocusEffect(useCallback(() => {
    const enquiryInterval = setInterval(sendEnquiry, 2000);
    return () => {
      clearInterval(enquiryInterval);
    };
  }, []));

  const sendEnquiry = async () => {
    if (connected) {
      const string = Buffer.from([0x05]).toString('utf8');
      await write(string);
    }
  };

  const triggerWritePumpSpeed = async () => {
    const commandPacket = {
      testCommand: 'hotPump',
      duty: targetPumpSpeed * 100,
    };
    await write(JSON.stringify(commandPacket));
  };
  
  const triggerTargetBoiler = async () => {
    const commandPacket = {
      testCommand: 'setBoiler',
      high: targetBoiler.high * 100,
      low: targetBoiler.low * 100,
    };
    // console.log(`triggerTargetBoiler: ${JSON.stringify(commandPacket)}`);
    await write(JSON.stringify(commandPacket));
  };

  const getIconNameByValveState = (valveState) => {
    switch(valveState) {
      case 0:
        return 'circle-slice-8';
      case 1:
        return 'circle-slice-4';
      case 2:
        return 'circle-outline';
    }
  };

  const getBoilerWaterLevelText = () => {
    switch(boilererWaterLevel) {
      case 0:
        return 'low';
      case 1:
        return 'mid';
      case 2:
        return 'full';
      default:
        return '';
    }
  };

  return (
    <View
      style={[styles.container, style]}
    >
      <View style={styles.inputContainer}>
        <VariableContainer title={'Valve'}>
          <ToggleButton.Row
            onValueChange={value => {
              if (value !== null && value !== undefined) {
                setTargetValveState(value)
              }
            }}
            value={targetValveState}
          >
            <ToggleButton style={styles.toggleButton} size={toggleButtonSize} icon={getIconNameByValveState(0)} value={0} />
            <ToggleButton style={styles.toggleButton} size={toggleButtonSize} icon={getIconNameByValveState(1)} value={1} />
            <ToggleButton style={styles.toggleButton} size={toggleButtonSize} icon={getIconNameByValveState(2)} value={2} />
          </ToggleButton.Row>
        </VariableContainer>
        <VariableContainer title={'Pump: '}>
          <View style={[styles.sliderContainer, { borderWidth: 0 }]}>
            <Text style={styles.variableFigureText}>
              {`${(targetPumpSpeed * 100).toFixed(0)}%`}
            </Text>
            <Slider
              style={{ width: '100%' }}
              minimumValue={0}
              maximumValue={1}
              value={targetPumpSpeed}
              onValueChange={setTargetPumpSpeed}
              onSlidingComplete={triggerWritePumpSpeed}
            />
          </View>
        </VariableContainer>
        <VariableContainer title={'Boiler'}>
          <View
            style={[
              styles.sliderContainer,
              { borderRadius: theme.roundness, marginHorizontal: 2, }
            ]}
          >
            <Headline>{'1000W'}</Headline>
            <Text style={styles.variableFigureText}>
              {`${(targetBoiler.high * 100).toFixed(0)}%`}
            </Text>
            <Slider
              style={{ width: '100%' }}
              minimumValue={0}
              maximumValue={1}
              value={targetBoiler.high}
              onValueChange={value => setTargetBoiler(prev => ({ high: value, low: prev.low }))}
              onSlidingComplete={triggerTargetBoiler}
            />
          </View>
          <View
            style={[
              styles.sliderContainer,
              { borderRadius: theme.roundness, marginHorizontal: 2, }
            ]}
          >
            <Headline>{'500W'}</Headline>
            <Text style={styles.variableFigureText}>
              {`${(targetBoiler.low * 100).toFixed(0)}%`}
            </Text>
            <Slider
              style={{ width: '100%' }}
              minimumValue={0}
              maximumValue={1}
              value={targetBoiler.low}
              onValueChange={value => setTargetBoiler(prev => ({ high: prev.high, low: value }))}
              onSlidingComplete={triggerTargetBoiler}
            />
          </View>
        </VariableContainer>
      </View>
      <View style={styles.verticalDivider} />
      <View style={styles.outputContainer}>
        <VariableContainer title={'Valve:'}>
          <Text style={styles.variableFigureText}>
            {`current=`}
          </Text>
          <IconButton size={toggleButtonSize} icon={getIconNameByValveState(hotWaterValveState.state)} />
          <Text style={styles.variableFigureText}>
            {`target=`}
          </Text>
          <IconButton size={toggleButtonSize} icon={getIconNameByValveState(hotWaterValveState.targetState)} />
        </VariableContainer>
        <VariableContainer title={'Pump'}>
          <Text style={styles.variableFigureText}>
            {`${(hotPumpState.speed * 100).toFixed(0)}%`}
          </Text>
        </VariableContainer>
        <VariableContainer title={'Water Chamber Thermometer'}>
          <Text style={styles.variableFigureText}>
            {`${(boilerTemperature).toFixed(1)}℃`}
          </Text>
        </VariableContainer>
        <VariableContainer title={'Nozzle Thermometer'}>
          <Text style={styles.variableFigureText}>
            {`${(outletTemperature).toFixed(1)}℃`}
          </Text>
        </VariableContainer>
        <VariableContainer title={'Water Chamber Level'}>
          <Text style={styles.variableFigureText}>
            {getBoilerWaterLevelText()}
          </Text>
        </VariableContainer>
        <VariableContainer title={'Heater'}>
          <View>
            <Text style={styles.variableFigureText}>
              {`1000W: ${(heaterState.high * 100).toFixed(0)}%`}
            </Text>
            <Text style={styles.variableFigureText}>
              {`500W: ${(heaterState.low * 100).toFixed(0)}%`}
            </Text>
          </View>
        </VariableContainer>
      </View>
    </View>
  );
}

const VariableContainer = ({ title, children }) => (
  <Card style={styles.variableSectionContainer}>
    <Card.Title title={title} />
    <Card.Content style={styles.variableSection}>
      {children}
    </Card.Content>
  </Card>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  verticalDivider: {
    height: '100%',
    width: StyleSheet.hairlineWidth,
    backgroundColor: 'black',
  },
  inputContainer: {
    flex: 3,
    justifyContent: 'center',
    padding: 20,
  },
  outputContainer: {
    flex: 2,
    justifyContent: 'center',
    padding: 20,
  },
  variableSectionContainer: {
    marginVertical: 10,
  },
  variableSection: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  variableFigureText: {
    fontSize: 20,
  },
  toggleButton: {
    width: toggleButtonSize,
    height: toggleButtonSize,
    marginHorizontal: 15,
  },
  sliderContainer: {
    paddingHorizontal: 10,
    alignItems: 'center',
    flex: 1,
    flexDirection: 'column',
    borderColor: 'black',
    borderWidth: StyleSheet.hairlineWidth,
    padding: 5,
  },
});
