import { DefaultTheme } from 'react-native-paper';
export default {
  ...DefaultTheme,
  dark: false,
  roundness: 12,
  colors: {
    ...DefaultTheme.colors,
    primary: 'rgb(89, 72, 72)',
    accent: 'rgb(191, 113, 84)',
    background: 'rgb(254, 249, 249)',
    surface: 'white',
    text: 'black',
    disabled: 'rgba(14, 13, 29, 0.21)',
    placeholder: 'rgb(174, 174, 174)',
    backdrop: 'rgba(40, 33, 33, 0.76)',
  },
};
