import React, { useCallback, useContext, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Text, Button, DataTable } from 'react-native-paper';
import { useFocusEffect } from '@react-navigation/native';
import i18n from 'i18n-js';
import { Text as SvgText, Path, G } from 'react-native-svg';
import * as d3 from 'd3';
import useSerialPort from '../hooks/useSerialPort';
import { LayoutContext } from '../components/Layout';
import FlexSvg from '../components/FlexSvg';
import dummyBrewData from '../assets/dummyBrewData';
import { ScrollView } from 'react-native-gesture-handler';

const getRandomDatum = (at) => {
  const randomTemperature = d3.randomUniform(90, 100);
  return {
    at: isNaN(at) ? Date.now() : at,
    targetTemperature: randomTemperature(),
    heaterTemperature: randomTemperature(),
    outletTemperature: randomTemperature(),
    p: d3.randomUniform(0.5)(),
    i: d3.randomUniform(2.0)(),
    d: d3.randomUniform(0.7)(),
  };
};
const getRandomData = (size) => {
  const data = [];
  for(let i = 0; i < size; i++) {
    data.push(getRandomDatum());
  }
  return data;
};

export default function HotDripTest ({ style }) {
  const layoutContext = useContext(LayoutContext);
  const { write, subscribe, connected } = useSerialPort();

  const [data, setData] = useState([]);//getRandomData(1000));
  const [brewMetadata, setBrewMetadata] = useState(null);
  const [bigChartDimension, setBigChartDimention] = useState({ width: 0, height: 0 });

  const handleStateReceivedEvent = ({ detail: state }) => {
    // console.log(`state = ${JSON.stringify(state)}`);
    const stateName = state.stateDetail.name;
    if (['brewing', 'paused', 'finished'].includes(stateName)) {
      // TODO: don't call it on every state update
      setBrewMetadata(prevBrewMetadata => {
        const newBrewMetadata = { ...prevBrewMetadata };
        if (state.stateDetail.recipe.id) {
          newBrewMetadata.recipeId = state.stateDetail.recipe.id;
        }
        if (state.heater.p) {
          newBrewMetadata.p = state.heater.p;
        }
        if (state.heater.i) {
          newBrewMetadata.i = state.heater.i;
        }
        if (state.heater.d) {
          newBrewMetadata.d = state.heater.d;
        }
        return newBrewMetadata;
      });

      const datum = {
        at: Date.now(),
        targetTemperature: state.heater.targetTemperature,
        heaterTemperature: state.hotThermometer.temperature,
        outletTemperature: state.outletThermometer.temperature,
        powerIndex: state.heater.powerIndex,
        p: state.heater.p,
        i: state.heater.i,
        d: state.heater.d,
      };
      setData(prevData => [...prevData, datum]);
    }
  };

  const margin = 80;
  const textHeight = 20;
  const scaleX = d3.scaleTime().domain(d3.extent(data, d => d.at)).nice().range([margin, bigChartDimension.width - margin]);
  const scaleXTickFormat = scaleX.tickFormat(0, "%M:%S.%L");
  const getScaleYDomain = () => {
    const heaterExtent = d3.extent(data, d => d.heaterTemperature);
    const outletExtent = d3.extent(data, d => d.outletTemperature);
    const domain = [Math.max(heaterExtent[1], outletExtent[1]), Math.min(heaterExtent[0], outletExtent[0])];
    return domain;
  };
  const scaleY = d3.scaleLinear()
    .domain(getScaleYDomain())
    .nice()
    .range([margin, bigChartDimension.height - margin]);
  const heaterTemperatureLine = d3.line()
    .defined(d => !isNaN(d.heaterTemperature))
    .x(d => scaleX(d.at))
    .y(d => scaleY(d.heaterTemperature));
  const outletTemperatureLine = d3.line()
    .defined(d => !isNaN(d.outletTemperature))
    .x(d => scaleX(d.at))
    .y(d => scaleY(d.outletTemperature));
  const powerIndexScale = d3.scaleLinear().domain([15, 0]).range([margin, bigChartDimension.height - margin]);
  const powerIndexLine = d3.line()
    .defined(d => !isNaN(d.powerIndex))
    .x(d => scaleX(d.at))
    .y(d => powerIndexScale(d.powerIndex))
    .curve(d3.curveStepAfter);
  // Since d3.axis depends on select, which depends on DOM henceforth unavailable,
  // we have to draw the axes and labels ourselves
  const xAxis = () => {
    const outerTick = 10;
    const innerTick = 10;

    let ticks, millisInterval = 500;
    do {
      ticks = scaleX.ticks(d3.utcMillisecond.every(millisInterval));
      if (ticks.length < 50) {
        break;
      }
      millisInterval *= 5;
    } while(true);
    
    const points = ticks.map(t => scaleX(t));
    const d = points.reduce((path, point) => {
      path += `H${point}`;
      path += `v${outerTick}`;
      path += `v-${outerTick}`;
      path += `v-${innerTick}`;
      path += `v${innerTick}`;
      return path;
    }, `M0,${bigChartDimension.height - margin}`);
    const tickFormat = scaleX.tickFormat(ticks.length, "%M:%S.%L");

    return (
      <G>
        <Path d={d} fill="none" stroke="black" strokeWidth={1} />
        {
          points.map((p, index) => {
            const x = p;
            const y = bigChartDimension.height - margin + textHeight / 2;
            return (
              <SvgText key={`x-${index}`} x={x} y={y} originX={x} originY={y} rotation="45">
                {scaleXTickFormat(ticks[index] - ticks[0])}
              </SvgText>
            );
          }, [])
        }
      </G>
    );
  };

  const yAxis = () => {
    const outerTick = 10;
    const innerTick = 10;

    const ticks = scaleY.ticks(20);
    const points = ticks.map(t => scaleY(t));
    const d = points.reduce((path, point) => {
      path += `V${point}`;
      path += `h${outerTick}`;
      path += `h-${outerTick}`;
      path += `h-${innerTick}`;
      path += `h${innerTick}`;
      return path;
    }, `M${margin},${bigChartDimension.height - margin}`);
    const tickFormat = scaleY.tickFormat(ticks.length);

    return (
      <G>
        <Path d={d} fill="none" stroke="black" strokeWidth={1} />
        {
          points.map((p, index) => (
            <SvgText key={`y-${index}`} x={20} y={p}>
              {tickFormat(ticks[index])}
            </SvgText>
          ), [])
        }
      </G>
    );
  };

  useEffect(() => {
    subscribe(handleStateReceivedEvent);
  }, []);

  useFocusEffect(useCallback(() => {
    // engageMonitor
    const enquiryInterval = setInterval(sendEnquiry, 100);
    return () => {
      clearInterval(enquiryInterval);
    };
  }, []));

  const sendEnquiry = async () => {
    if (connected) {
      const string = Buffer.from([0x05]).toString('utf8');
      await write(string);
    }
  };

  const handleWritePress = async () => {
    const string = Buffer.from([0x05]).toString('utf8');
    try {
      await write(string);
    } catch (err) {
      console.log(err);
      layoutContext.setSnackbar(prevSnackbar => ({
        ...prevSnackbar,
        visible: true,
        text: i18n.t(err.message),
      }));
    }
  };

  return (
    <View style={[styles.container, style]}>
      <FlexSvg style={styles.bigChart} onLoad={setBigChartDimention}>
        <Path d={powerIndexLine(data)} fill="none" stroke="blue" strokeWidth={1} />
        <Path d={heaterTemperatureLine(data)} fill="none" stroke="tomato" strokeWidth={2} />
        <Path d={outletTemperatureLine(data)} fill="none" stroke="green" strokeWidth={2} />
        {xAxis()}
        {yAxis()}
      </FlexSvg>
      <View style={styles.smallChartsContainer}>
        <View style={[styles.smallChart, { padding: 20 }]}>
          <Text style={styles.metadataText}>
            {`brew id = ${brewMetadata?.recipeId}`}
          </Text>
          <Text style={styles.metadataText}>
            {`started at = ${data[0]?.at}`}
          </Text>
          <Text style={styles.metadataText}>{`datum count = ${data.length}`}</Text>
          <Text style={styles.metadataText}>{`P = ${brewMetadata?.p}`}</Text>
          <Text style={styles.metadataText}>{`I = ${brewMetadata?.i}`}</Text>
          <Text style={styles.metadataText}>{`D = ${brewMetadata?.d}`}</Text>
        </View>
        <DataTable style={[styles.smallChart, { flex: 2 }]}>
          <DataTable.Header>
            <DataTable.Title>{'elapsed time'}</DataTable.Title>
            <DataTable.Title numeric>{'heater temperature'}</DataTable.Title>
            <DataTable.Title numeric>{'outlet temperature'}</DataTable.Title>
            <DataTable.Title numeric>{'power index'}</DataTable.Title>
          </DataTable.Header>

          <ScrollView>
          {data.map((d, index) => (
            <DataTable.Row key={`data-${index}`}>
              <DataTable.Cell>{scaleXTickFormat(d.at - data[0].at)}</DataTable.Cell>
              <DataTable.Cell numeric>{d.heaterTemperature.toFixed(2)}</DataTable.Cell>
              <DataTable.Cell numeric>{d.outletTemperature.toFixed(2)}</DataTable.Cell>
              <DataTable.Cell numeric>{d.powerIndex}</DataTable.Cell>
            </DataTable.Row>
          ))}
          </ScrollView>
        </DataTable>
      </View>
      {/* <Button mode="contained" onPress={handleWritePress}>
        {}
      </Button> */}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 30,
    paddingVertical: 20,
  },
  bigChart: {
    height: '70%',
    marginHorizontal: 10,
    borderWidth: 1,
    borderColor: 'chocolate',
  },
  smallChartsContainer: {
    height: '30%',
    flexDirection: 'row',
    marginTop: 20,
  },
  smallChart: {
    flex: 1,
    margin: 10,
    borderColor: 'chocolate',
    borderWidth: 1,
  },
  metadataText: {
    fontSize: 20,
  },
});
