import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { CommonActions, DrawerActions } from '@react-navigation/native';
import { DrawerContentScrollView } from '@react-navigation/drawer';
import { Drawer } from 'react-native-paper';

export default function CustomDrawer(props) {
  const { state, descriptors, itemStyle, navigation } = props;
  const [sections, setSections] = useState(() => 
    state.routes.reduce((acc, route, index) => {
      const sectionDividerCharAt = route.name.indexOf('/');
      let sectionName;
      if (sectionDividerCharAt >= 0) {
        sectionName = route.name.substring(0, sectionDividerCharAt);
      } else {
        sectionName = 'ZZZ';
      }

      if (!acc.hasOwnProperty(sectionName)) {
        acc[sectionName] = [];
      }
      acc[sectionName].push({...route, index});
      return acc;
    }, []).sort()
  );

  return (
    <DrawerContentScrollView {...props} contentContainerStyle={styles.drawerContainer}>
      {Object.entries(sections).map(([sectionName, section], sectionIndex) => {
        const newSectionMap = section.map((route, i) => {
          const focused = route.index === state.index;
          const { title, drawerLabel, drawerIcon } = descriptors[route.key].options;

          return (
            <Drawer.Item
              key={route.key}
              label={drawerLabel ?? title ?? route.name}
              icon={drawerIcon}
              active={focused}
              style={[itemStyle, styles.drawerItem]}
              onPress={() => {
                navigation.dispatch({
                  ...(focused
                    ? DrawerActions.closeDrawer()
                    : CommonActions.navigate(route.name)),
                  target: state.key,
                });
              }}
            />
          );
        });
        if (sectionName === 'ZZZ') {
          return newSectionMap;
        } else {
          return (
            <Drawer.Section title={sectionName} key={`section-${sectionIndex}`}>
              {newSectionMap}
            </Drawer.Section>
          );
        }
      })}
    </DrawerContentScrollView>
  );
}

const styles = StyleSheet.create({
  drawerContainer: {
    height: window.innerHeight,
  },
  drawerItem: {
    paddingLeft: 20,
  },
});
