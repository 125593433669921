import i18n from 'i18n-js';
import en from './en';
import zh from './zh';

i18n.locale = navigator.language;
i18n.fallbacks = true;
i18n.translations = {
  en,
  zh,
};

export default i18n;
